// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-employers-page-js": () => import("./../src/templates/employers-page.js" /* webpackChunkName: "component---src-templates-employers-page-js" */),
  "component---src-templates-investors-page-js": () => import("./../src/templates/investors-page.js" /* webpackChunkName: "component---src-templates-investors-page-js" */),
  "component---src-templates-recruitment-agencies-page-js": () => import("./../src/templates/recruitment-agencies-page.js" /* webpackChunkName: "component---src-templates-recruitment-agencies-page-js" */),
  "component---src-templates-rural-community-page-js": () => import("./../src/templates/rural-community-page.js" /* webpackChunkName: "component---src-templates-rural-community-page-js" */),
  "component---src-templates-article-page-js": () => import("./../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-success-index-js": () => import("./../src/pages/contact/success/index.js" /* webpackChunkName: "component---src-pages-contact-success-index-js" */),
  "component---src-pages-investors-success-index-js": () => import("./../src/pages/investors/success/index.js" /* webpackChunkName: "component---src-pages-investors-success-index-js" */),
  "component---src-pages-recruitment-agencies-success-index-js": () => import("./../src/pages/recruitment-agencies/success/index.js" /* webpackChunkName: "component---src-pages-recruitment-agencies-success-index-js" */),
  "component---src-pages-rural-community-success-index-js": () => import("./../src/pages/rural-community/success/index.js" /* webpackChunkName: "component---src-pages-rural-community-success-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

